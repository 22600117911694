:root {
  --accent: #dc8f31;
  --piece-background: rgba(255, 246, 80, 0.722);
  --square-background: rgba(255, 217, 0, 0.478);
  --text: rgb(198, 198, 198);
  --text-hover: rgb(255, 255, 255);
  --text2: rgb(0, 0, 0);
  --background: #36322e;
  --dark-background: #211e1c;
  --light-background: #5d564d;
  --glow-color: rgba(253, 243, 152, 0.36);
  --hue: 0deg;
  --sat: 100%;
  --unit: .7vw;
  --vminUnit: .7vw;
  --secondary-rotate: 0deg;
  --link: rgb(114, 171, 255);
}

/* :root {
  --accent: #c9ba45;
  --piece-background: rgba(255, 246, 80, 0.722);
  --square-background: rgba(255, 251, 0, 0.478);
  --text: rgb(198, 198, 198);
  --text-hover: rgb(255, 255, 255);
  --text2: rgb(0, 0, 0);
  --background: #36352e;
  --dark-background: #21211c;
  --light-background: #696858;
  --glow-color: rgba(253, 253, 152, 0.605);
} */

/* :root {
  --accent: #c94545;
  --piece-background: rgba(255, 80, 80, 0.722);
  --square-background: rgba(255, 0, 0, 0.478);
  --text: rgb(198, 198, 198);
  --text-hover: rgb(255, 255, 255);
  --text2: rgb(0, 0, 0);
  --background: #362e2e;
  --dark-background: #211c1c;
  --light-background: #695858;
  --glow-color: rgba(253, 253, 152, 0.605);
} */

/* :root {
  --accent: #4549c9;
  --piece-background: rgba(80, 86, 255, 0.722);
  --square-background: rgba(0, 4, 255, 0.478);
  --text: rgb(198, 198, 198);
  --text-hover: rgb(255, 255, 255);
  --text2: rgb(0, 0, 0);
  --background: #2e2e36;
  --dark-background: #1c1c21;
  --light-background: #585869;
  --glow-color: rgba(253, 253, 152, 0.605);
} */

/* :root {
  --accent: #c98045;
  --piece-background: rgba(255, 150, 80, 0.722);
  --square-background: rgba(255, 140, 0, 0.478);
  --text: rgb(198, 198, 198);
  --text-hover: rgb(255, 255, 255);
  --text2: rgb(0, 0, 0);
  --background: #36322e;
  --dark-background: #211e1c;
  --light-background: #696158;
  --glow-color: rgba(253, 213, 152, 0.605);
} */

.blog h2 {
  font-size: calc(var(--unit) * 2.4);
  color: var(--text-hover);
  margin: calc(var(--unit) * .5);
}
.blog h3 {
  color: var(--text-hover);
  margin: calc(var(--unit) * .5)
}
.blog h4 {
  font-size: calc(var(--unit) * 4.5);
  color: var(--text-hover);
  border-left: calc(var(--unit) * .3) solid var(--accent);
  padding-left: calc(var(--unit) * .3);
}
.blog p {
  color: var(--text);
  margin: calc(var(--unit) * .5);
}
.blog img, iframe {
  outline: calc(var(--unit) * .15) solid var(--accent);
}
.blogs-box {
  outline: calc(var(--unit) * .25) solid var(--accent);
  background-color: var(--dark-background);
}
.blog-content {
  /* outline: calc(var(--unit) * .25) solid var(--accent); */
  /* background-color: var(--dark-background); */
}

@keyframes rainbow {
  0%   {color: rgb(255, 141, 141); padding-bottom: 0px;}
  25%  {color: rgb(255, 198, 129); padding-bottom: 10px;}
  50%  {color: rgb(255, 255, 129); padding-bottom: 0px;}
  75%  {color: rgb(114, 255, 114); padding-bottom: 10px;}
  100% {color: rgb(117, 117, 255); padding-bottom: 0px;}
}

* {
  padding: 0;
  margin: 0;
  /* filter: hue-rotate(var(--hue)); */
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: var(--background);
  filter: hue-rotate(var(--hue)) saturate(var(--sat));
  transition-duration: .5s;
}

h1 {
  text-align: left;
  font-size: calc(var(--unit) * 4.5);
  font-family: Arial, Helvetica, sans-serif;
  color: var(--text2);
  padding: calc(var(--unit) * 4.0);
}

h2 {
  font-size: calc(var(--unit) * 3.0);
  line-height: .9;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: calc(var(--unit) * 5.0);
  }
}

.wccpage h2 {
  color: var(--text);
}
.wccpage p {
  color: var(--text);
  margin: calc(var(--unit) * 1.0);
}

h3 {
  font-size: calc(var(--unit) * 2.2);
}

/* .list {
  display: flex;
  justify-content: center;
  width: 100%;
}

.list ul li {
  list-style: none;
  margin: 42px;
  text-align: center;
  /* justify-content: space-evenly;
}*/

a {
  text-decoration: none;
  /* color: rgb(0, 0, 0); */
  color: var(--text);
  font-size: calc(var(--unit) * 2.0);
  /* font-family: Arial, Helvetica, sans-serif; */
  padding: calc(var(--unit) * 1.4) calc(var(--unit) * 2.5);
  /* background-color: transparent; */
  /* border: 2px solid rgb(12, 0, 66); */
  transition-duration: .5s;
  text-align: center;
  border-radius: 25%;
}

a:hover {
  /* background-color: rgb(12, 0, 66); */
  color: var(--text-hover);
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

button {
  /* border-radius: 0%; */
  /* border-color: var(--accent); */
  border-radius: calc(var(--unit) * 1.0);
  border: calc(var(--unit) * .3) solid var(--accent);
  color: var(--text);
  background-color: var(--background);
  transition-duration: 0.5s;
  cursor: pointer;
}

button:hover {
  color: var(--text-hover);
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  /* background-color: var(--dark-background); */
}
button:active {
  background-color: var(--dark-background);
}

.toggle-on {
  border: 3px solid var(--accent);
}
.toggle-off {
  border: 3px solid var(--background);
}

nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 5vh;
  max-height: 9vh;
  background-color: var(--dark-background);
  color: var(--text);
  /* border-radius: 10px; */
  border: 3px solid var(--accent);

  /* padding-left: 30%; */
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  /* border: 2px solid yellow; */
}

.nav-holder {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  max-height: 9vh;
  background-color: var(--dark-background);
  color: var(--text);
  /* border-radius: 10px; */
  /* border: 3px solid var(--accent); */
  
  flex-direction: column;
  /* border: 1px solid green; */

  /* padding-left: 30%; */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

.nav-links {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  list-style: none;

  flex-direction: row;

  max-height: 4.5vh;
  
  text-decoration: none;
  font-size: calc(var(--unit) * 1.6);
  /* border: 1px solid red; */
}
.nav-links h2 {
  padding-left: calc(var(--unit) * 1.5);
  padding-right: calc(var(--unit) * 1.5);
  font-size: calc(var(--unit) * 1.6);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* border: 1px solid yellow; */
}
.nav-links li {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  /* border: 1px solid blue; */
  max-height: 4.5vh;
}

@media screen and (max-width: 768px) {
  .nav-links h2 {
    font-size: calc(var(--unit) * 2.4);
  }
}

.ascii-art {
  /* height: ; */
  /* display: grid;
  height: 100%;
  width: 100%; */
  display: flex;
  /* justify-content: space-evenly; */
  /* align-items: center; */
  background-color: var(--dark-background);
  /* border: 3px solid var(--accent); */
  position: absolute;
  left: 50%;
  width: 100%;
  height: 90%;
  /* width: fit-content; */
  /* top: 50%; */
  transform: translate(-50%, 0%);
}

.fischer-quote {
  position: absolute;
  font-family: monospace;
  text-align: center;
  font-size: calc(var(--unit) * 2.5);
  color: rgb(213, 211, 171);
  padding: calc(var(--unit) * 2.0);
  width: 50%;
  white-space: pre-wrap;
  /* position: absolute; */
  transform: translate(-50%, calc(var(--unit) * 15));
}

.fischer-quote2 {
  position: absolute;
  font-family: monospace;
  text-align: center;
  font-size: calc(var(--unit) * 2.5);
  color: rgb(213, 211, 171);
  padding: calc(var(--unit) * 2.0);
  width: 50%;
  white-space: pre-wrap;
  /* position: absolute; */
  transform: translate(50%, calc(var(--unit) * 25));
}

.desc {
  /* position: absolute; */
  /* font-family: monospace; */
  text-align: left;
  font-size: calc(var(--unit) * 2.0);
  color: var(--text);
  padding: calc(var(--unit) * 2.0);
  /* width: 35%; */
  white-space: pre-wrap;
  width: calc(var(--unit) * 35.0);
  /* position: absolute; */
  /* transform: translate(-200px, -300px); */
}
.twitter-link {
  text-align: left;
  color:rgb(99, 164, 255);
}
.twitter-link:hover {
  color:rgb(34, 92, 174);
}

.fit-image {
  pointer-events: none;
  position: absolute;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.app {
  min-height: 100vh;
  background-color: var(--background);
}

.no-drag {
  -webkit-user-drag: none;
}

.button-panel {
  position: absolute;
  left: calc(var(--unit) * 64.0);
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(var(--unit) * 51.2);
  width: calc(var(--unit) * 25.6);
  background-color: var(--light-background);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* border: 5px solid var(--accent); */
}

.button-icon {
  height: calc(var(--unit) * 4.0);
  width: calc(var(--unit) * 4.0);
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-user-drag: none;
  transition-duration: 0.5s;
}
button:hover .button-icon {
  filter: opacity(75%);
}
button:active .button-icon {
  filter: opacity(50%);
}

.button-icon2 {
  height: calc(var(--unit) * 4.5);
  width: calc(var(--unit) * 4.5);
  -webkit-user-drag: none;
  transition-duration: 0.5s;
}
button:hover .button-icon2 {
  filter: opacity(75%);
}
button:active .button-icon2 {
  filter: opacity(50%);
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-buttons {
  display: flex;;
  flex-direction: row;
  align-items: center;
}

.panel-button {
  height: fit-content;
  width: fit-content;
  width: calc(var(--unit) * 15.0);
  padding-left: calc(var(--unit) * 1.5);
  padding-right: calc(var(--unit) * 1.5);
  font-size: calc(var(--unit) * 1.8);
}

.small-button {
  height: calc(var(--unit) * 5.0);
  width: calc(var(--unit) * 7.5);
  padding-left: calc(var(--unit) * 1.5);
  padding-right: calc(var(--unit) * 1.5);
}
.smaller {
  margin: calc(var(--unit) * 1);
  position: relative;
  height: calc(var(--unit) * 3.5);
  width: calc(var(--unit) * 3.5);
  padding-left: calc(var(--unit) * 1.5);
  padding-right: calc(var(--unit) * 1.5);
}

.in-line-button {
  margin-left: calc(var(--unit) * 1);
  margin-right: calc(var(--unit) * 1);
  /* margin-top: calc(var(--unit) * 1); */
  /* margin: 0 auto; */
  /* position: relative; */
  /* height: calc(var(--unit) * 3.5);
  width: calc(var(--unit) * 3.5); */
  /* height: 100%; */
  padding: calc(var(--unit) * .2);
  padding-left: calc(var(--unit) * 1.5);
  padding-right: calc(var(--unit) * 1.5);
}
.arrow-icon {
  width: 80%;
  height: 80%;
}

.database-selection {
  width: calc(var(--unit) * 6);
  width: fit-content;
}

.time-selection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.rating-selection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.db-selection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.selection-button {
  border-radius: 0%;
  margin: calc(var(--unit) * .1);
  padding: calc(var(--unit) * .5);
  width: 100%;
  height: 100%;
  font-size: calc(var(--unit) * 1.2);
}

.selected-button {
  border: calc(var(--unit) * .3) solid var(--accent);
}

.graph-image {
  border: calc(var(--unit) * .3) solid var(--accent);
}

.unselected-button {
  border: none;
}

.fen {
  display: inline-block;
  word-wrap: break-word;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: fit-content;
  width:  fit-content;
  white-space: pre;
  text-align: center;
  font-size: calc(var(--unit) * 1.3);
  color: white;
  background-color: var(--light-background);
}

/*
 ---------------------------- FOLLOW TOURNAMENTS ----------------------------
*/
.knockout-title {
  color: var(--text);
  margin: calc(var(--unit) * 1);
}

.bracket-container {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  /* outline-offset: calc(var(--unit) * 2);
  outline: calc(var(--unit) * .2) solid var(--accent); */
}

.knockout-tournament-container {
  margin: 0 auto;
  margin-bottom: calc(var(--unit) * 3);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.knockout-tab {
  transition-duration: 0;
  transition-delay: 0;
  width: calc(var(--unit) * 10);
  height: calc(var(--unit) * 3);
  font-size: larger;
  background-color: var(--dark-background);
}

.knockout-tab-big {
  transition-duration: 0;
  transition-delay: 0;
  width: calc(var(--unit) * 15);
  height: calc(var(--unit) * 4.5);
  font-size: larger;
  background-color: var(--dark-background);
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.bracket-zoom {
  -webkit-appearance: none;
  height: calc(var(--unit) * 1.3);
  width: calc(var(--unit) * 25);
  background-color: var(--background);
  background-image: linear-gradient(var(--dark-background), var(--dark-background));
  background-repeat: no-repeat;
  border: calc(var(--unit) * .1) solid var(--light-background);
  margin: 0 auto;
}
.bracket-zoom::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--accent);
  height: calc(var(--unit) * 1.5);
  width: calc(var(--unit) * 1.5);
  border-radius: 50%;
}

.bracket-line {
  margin: 0;
  background-color: var(--light-background);
  position: absolute;
}

.bracket-slot {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5), calc(var(--unit) * 0) calc(var(--unit) * .5) rgb(0,0,0,.5);
  margin: 0;
  outline-offset: calc(var(--unit) * -.2);
  /* outline: calc(var(--unit) * .2) solid var(--light-background); */
  position: absolute;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: left;
  /* transition-duration: .5s; */
  cursor: pointer;
}
.bracket-slot:hover {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.15), calc(var(--unit) * .0) calc(var(--unit) * .75) rgb(0,0,0,.5);
  outline: calc(var(--unit) * .2) solid var(--accent);
}

.bracket-slot:hover {
  overflow: visible;
}

.knockout-name {
  position: absolute;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  line-height: 100%;
  margin-left: 5%;
  text-shadow: calc(var(--unit) * .1) calc(var(--unit) * .1) 0 #000, calc(var(--unit) * .1) calc(var(--unit) * -.1) 0 #000, calc(var(--unit) * -.1) calc(var(--unit) * .1) 0 #000, calc(var(--unit) * -.1) calc(var(--unit) * -.1) 0 #000;
}

.probability-pop-up {
  position: absolute;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  line-height: 100%;
  margin-left: 5%;
  text-shadow: calc(var(--unit) * .1) calc(var(--unit) * .1) 0 #000, calc(var(--unit) * .1) calc(var(--unit) * -.1) 0 #000, calc(var(--unit) * -.1) calc(var(--unit) * .1) 0 #000, calc(var(--unit) * -.1) calc(var(--unit) * -.1) 0 #000;
}

.tournament {
  margin-top: calc(var(--unit) * 5);
}

.tournament-container {
  border: calc(var(--unit) * .2) solid var(--accent);
  transition-duration: .5s;
  /* border: 5px solid var(--accent); */
  /* border-radius: 5%; */
  width: min(calc(var(--unit) * 87.8), 100%);
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items:stretch; */
  /* padding: 50px; */
  margin: 0 auto;
  margin-top: calc(var(--unit) * 2.4);
  margin-bottom: calc(var(--unit) * 9.6);
  /* margin:50px; */
  /* background-color: var(--light-background); */
}
.tournament-container:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.tournament-info-and-help {
  /* border: 5px solid green; */
  align-items: center;
  position: relative;
}

.tournament-info {
  /* border: 3px solid red; */
  width: 100%;
  margin: 0px;
  border: calc(var(--unit) * .5) solid var(--accent);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-hover);
  text-align: center;
  background-color: var(--dark-background);
  transition-duration: .5s;
}
.tournament-info:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.games-and-standings-container {
  /* border-radius: 5%; */
  /* border: 5px solid var(--dark-background); */
  width: 100%;
  height: 100%;
  /* border: 3px solid red; */
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
.games-and-standings-container:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

.standings-and-moves {
  /* border-radius: 5%; */
  /* border: 5px solid var(--dark-background); */
  /* width: 100%;
  height: 100%; */
  /* border: 3px solid red; */
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-around;
  margin: 0 auto;
}
/* @media screen and (max-width: 768px) {
  .standings-and-moves {
    flex-direction: column;
  }
} */

.standings-container {
  margin: calc(var(--unit) * 1.6);
  background-color: var(--light-background);
  border: calc(var(--unit) * .3) solid var(--accent);
  /* width: calc(var(--unit) * 25.6); */
  /* height: calc(var(--unit) * 60.0); */
  transition-duration: .5s;
  /* width: 100%; */
  /* border: 3px solid blue; */
  text-align: center;
  width: fit-content;
  height: fit-content;
}
.standings-container:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.moves-container {
  margin: calc(var(--unit) * 1.6);
  margin-top: 0;
  background-color: var(--light-background);
  border: calc(var(--unit) * .3) solid var(--accent);
  /* width: calc(var(--unit) * 25.6); */
  /* height: calc(var(--unit) * 60.0); */
  transition-duration: .5s;
  /* width: 100%; */
  /* border: 3px solid blue; */
  text-align: center;
  width: fit-content;
  height: fit-content;
}
.moves-container:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}


table, th, td {
  color: var(--text);
  border-collapse: collapse;
  padding: 0;
  border: calc(var(--unit) * .3) solid var(--accent);
  /* width: 100%; */
  /* display: block; */
  /* overflow: auto; */
  /* font-size: calc(var(--unit) * 1.0); */
}
table {
  /* height: 0 */
}
table:hover, th:hover, td:hover {
  color: var(--text-hover);
}
th {
  /* border: 3px solid blue; */
  text-align: center;
  font-size: calc(var(--unit) * 1.4);
  padding-left: calc(var(--unit) * .5);
  padding-right: calc(var(--unit) * .5);
  background-color: var(--dark-background);
  color: var(--text-hover);
}
.clickable-th {
  /* border-radius: 40%; */
  color: var(--text);
  border: calc(var(--unit) * .3) solid var(--accent);
}
.clickable-th:hover {
  cursor: pointer;
  color: var(--text-hover);
  background-color: var(--background);
}
td {
  /* position: absolute; */
  padding-left: calc(var(--unit) * .5);
  padding-right: calc(var(--unit) * .5);
  text-align: center;
  padding: calc(var(--unit) * .5);
  font-size: calc(var(--unit) * 1.4);
  /* background-color: var(--background); */
  /* border: 3px solid yellow; */
  color: var(--text-hover);
  height: 100%;
  /* width: 50%; */
}
td b {
  padding: 0;
  /* border: 3px solid blue; */
}

.reverse-hue-change {
  filter: hue-rotate(calc(-1*var(--hue)));
}

.tournament-games-container {
  /* background-color: var(--light-background); */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 25%; */
  width: calc(var(--unit) * 51.2);
  /* height: 1374px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  /* border: 5px solid var(--accent); */
  margin: 0 auto;
  margin: calc(var(--unit) * .8);
  /* margin-top: 32px; */
}
.tournament-games-container:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

.tournament-games-container2 {
  /* background-color: var(--light-background); */
  position: relative;
  /* top: 50%; */
  width: calc(var(--unit) * 51.2);
  /* height: 1374px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  /* border: 5px solid var(--accent); */
  margin: 0 auto;
  margin-bottom: calc(var(--unit) * 2.4);
  margin-top: 0;
  /* margin: calc(var(--unit) * .8); */
  /* margin: 20%; */
  /* margin-top: 32px; */
  /* border: 3px solid red; */
}
.tournament-games-container2:hover {
  color: var(--text-hover);
  /* border: 5px solid var(--text-hover); */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

/*
 ---------------------------- TOURNAMENT GAMES ----------------------------
*/
.game-info {
  /* border: 3px solid red; */
  /* background-color: orange; */
  /* position: relative;
  top: -128px;
  left: 128px; */
  /* border: 5px solid blue; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
  /* margin: 5px; */
  flex-direction: row;
  width: calc(var(--unit) * 31.0);
  height: calc(var(--unit) * 12.8);
  color: var(--text);
  text-align: center;
  transition-duration: .5s;
}
.game-info:hover {
  color: var(--text-hover);
}

.game-player-info {
  margin-top: calc(var(--unit) * 1.6);
  margin-bottom: 0px;
  color: var(--text-hover)
}

.game-eval-info {
  /* margin-top: 16px; */
  /* margin-bottom: 16px; */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(var(--unit) * 1.3);
}



.eval-bar-container {
  width: calc(var(--unit) * 25.0);
  height: calc(var(--unit) * 1.6);
  /* border-radius: 5%; */
  background-color: gray;
  margin: 0 auto;
  display: flex;
}
.eval-bar {
  height: calc(var(--unit) * 1.6);
}
.eval-bar:hover {
  border: calc(var(--unit) * .3) solid var(--accent);
  height: calc(var(--unit) * 2.2);
  margin: 0 auto;
  margin-top: calc(var(--unit) * -.3);
}

.prediction-bar-container {
  width: calc(var(--unit) * 25.0);
  height: calc(var(--unit) * 1.6);
  /* border-radius: 5%; */
  background-color: gray;
  margin: 0 auto;
  /* margin-top: 16px; */
  display: flex;
}
.prediction-bar {
  height: calc(var(--unit) * 1.6);
}
.prediction-bar:hover {
  border: calc(var(--unit) * .3) solid var(--accent);
  height: calc(var(--unit) * 2.2);
  margin: 0 auto;
  margin-top: calc(var(--unit) * -.3);
}

.bar-label {
  margin-top: calc(var(--unit) * .4);
  /* text-align: left;
  margin-left: 32px; */
  text-align: center;
}

.white-bar {
  background-color: white;
  color: rgba(0, 0, 0, 0);
}
.white-bar:hover {
  color: black;
}

.black-bar {
  background-color: black;
  color: rgba(0, 0, 0, 0);
}
.black-bar:hover {
  color: white;
}

.gray-bar {
  background-color: gray;
  color: rgba(0, 0, 0, 0);
}
.gray-bar:hover {
  color: black;
}

.white-eval-bar {
  background-color: white;
  color: black;
}
.black-eval-bar {
  background-color: black;
  color: white;
}


.game-info-and-buttons {
  position: relative;
  width: calc(var(--unit) * 36.9);
  height: calc(var(--unit) * 12.8);
  /* border: 3px solid red; */
  top: calc(var(--unit) * -12.8);
  left: calc(var(--unit) * 12.8);
}
/* .game-info-and-buttons:hover::after {
  content: "Click to Expand";
  pointer-events: none;
  z-index: 2;
  background-color: var(--dark-background);
  border: calc(var(--unit) * .3) solid var(--accent);
  padding: calc(var(--unit) * .3);
  position: relative;
  left: calc(var(--unit) * -12.6);
  top: calc(var(--unit) * -9.0);
  filter: opacity(70%);
} */
/* .board-expandable:hover::after {
  content: "Click to Expand";
  white-space: nowrap;
  pointer-events: none;
  z-index: 2;
  background-color: var(--dark-background);
  border: calc(var(--unit) * .3) solid var(--accent);
  padding: calc(var(--unit) * .3);
  position: relative;
  left: calc(var(--unit) * .2);
  top: calc(var(--unit) * -7.7);
  filter: opacity(100%);
} */

.expanded-board-stuff {
  position: relative;
  width: calc(var(--unit) * 49.7);
  height: calc(var(--unit) * 51.2);
  top: calc(var(--unit) * -44.8);
  /* left: 128px; */
  /* border: 3px solid red; */
  /* background-color: var(--background); */
}

.move-buttons {
  position: absolute;
  right: calc(var(--unit) * .5);
  bottom: calc(var(--unit) * .0);
  height: calc(var(--unit) * 6.4);
  width: calc(var(--unit) * 11.3);
  /* background-color: red; */
  /* border: 3px solid lime; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: calc(var(--unit) * .5);
}
.arrow-buttons {
  /* background-color: blue; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.move-button {
  width: 100%;
  height: 100%;
  border: none;
  filter: opacity(50%);
}
.live-button {
  height: 70%;
  width: 100%;
  border: none;
  filter: opacity(50%);
}

.expanded-result-buttons {
  position: absolute;
  height: calc(var(--unit) * 4.3);
  width: calc(var(--unit) * 11.8);
  right: calc(var(--unit) * .0);
  top: calc(var(--unit) * 1.0);
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  align-items: center;
}
.expanded-result-button {
    position: relative;
    height: calc(var(--unit) * 4.0);
    width: calc(var(--unit) * 4.0);
    font-size: calc(var(--unit) * 1.3);
    text-align: center;
    padding: 0;
    /* border-radius: 0; */
    /* border: 0; */
    filter: opacity(30%);
    /* border: 3px solid gray; */
    /* filter: grayscale(50%); */
    border: none;
}
.expanded-result-button:hover {
  filter: opacity(100%);
}
.minimize-button {
  position: absolute;
  left: calc(var(--unit) * .4);
  top: calc(var(--unit) * .4);
  width: calc(var(--unit) * 3.0);
  height: calc(var(--unit) * 2.5);
  font-size: calc(var(--unit) * 5.0);
  text-align: center;
  display: flex;
  align-items: center;
  padding: calc(var(--unit) * .4);
}
.mini {
  width: calc(var(--unit) * 2.0);
  height: calc(var(--unit) * .3);
  background-color: white;
}
.minimize-button:hover .mini {
  background-color: rgb(207, 207, 207);
}
.minimize-button:active .mini {
  background-color: rgb(179, 179, 179);
}

.player-info {
  display: flex;
  justify-content: space-around;
  width: calc(var(--unit) * 38.4);
  height: calc(var(--unit) * 6.4);
  color: var(--text-hover);
  background-color: var(--background);
  padding: calc(var(--unit) * 1.6);
  border-right: calc(var(--unit) * .3) solid var(--accent);
}

/* .black-player {
  border: 3px solid black;
} */
.white-player {
  position: absolute;
  bottom: 0;
  /* border: 3px solid white; */
}



.vert-eval-bar-container {
  position: absolute;
  top: calc(var(--unit) * 6.4);
  right: calc(var(--unit) * 6.4);
  /* border: 3px solid blue; */
  width: calc(var(--unit) * 3.2);
  height: calc(var(--unit) * 38.4);
  /* border-radius: 5%; */
  background-color: gray;
  margin: 0 auto;
  display: flex;
  display: flex;
  flex-direction: column;
}
.vert-eval-bar {
  font-size: calc(var(--unit) * 1.0);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--unit) * 3.2);
}
.vert-eval-bar:hover {
  border: calc(var(--unit) * .3) solid var(--accent);
  width: calc(var(--unit) * 3.8);
  margin: 0 auto;
  margin-left: calc(var(--unit) * -.3);
}

.vert-prediction-bar-container {
  position: absolute;
  top: calc(var(--unit) * 6.4);
  right: calc(var(--unit) * 1.6);
  width: calc(var(--unit) * 3.2);
  height: calc(var(--unit) * 38.4);
  /* border-radius: 5%; */
  background-color: gray;
  margin: 0 auto;
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
}
.vert-prediction-bar {
  font-size: calc(var(--unit) * 1.3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--unit) * 3.2);
}
.vert-prediction-bar:hover {
  border: calc(var(--unit) * .3) solid var(--accent);
  width: calc(var(--unit) * 3.8);
  margin: 0 auto;
  margin-left: calc(var(--unit) * -.3);
}

.bar-label {
  margin-top: calc(var(--unit) * .4);
  /* text-align: left;
  margin-left: 32px; */
  text-align: center;
}

.white-bar {
  background-color: white;
  color: rgba(0, 0, 0, 0);
}
.white-bar:hover {
  color: black;
}

.black-bar {
  background-color: black;
  color: rgba(0, 0, 0, 0);
}
.black-bar:hover {
  color: white;
}

.gray-bar {
  background-color: gray;
  color: rgba(0, 0, 0, 0);
}
.gray-bar:hover {
  color: black;
}

.white-eval-bar {
  background-color: white;
  color: black;
}
.black-eval-bar {
  background-color: black;
  color: white;
}



.result-buttons {
  position: absolute;
  /* border: 3px solid yellow; */
  height: calc(var(--unit) * 12.8);
  width: calc(var(--unit) * 5.9);
  right: calc(var(--unit) * .0);
  top: calc(var(--unit) * -1.6);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.result-button {
  position: relative;
  height: calc(var(--unit) * 4.3);
  width: calc(var(--unit) * 5.9);
  font-size: calc(var(--unit) * 1.6);
  text-align: center;
  padding: 0;
  /* border-radius: 0; */
  /* border: 0; */
  filter: opacity(30%);
  /* border: 3px solid gray; */
  /* filter: grayscale(50%); */
  border: none;
}
.result-button:hover {
  filter: opacity(100%);
}

.black-result {
  background-color: black;
  color: white;
  /* color: var(--accent); */
}
.black-result:hover {
  background-color: rgb(51, 51, 51);
  color: rgb(215, 215, 215);
}
.black-result:active {
  background-color: rgb(93, 93, 93);
  color: rgb(180, 180, 180);
}

.draw-result {
  background-color: gray;
  color: white;
  /* color: var(--accent); */
  /* border: none; */
}
.draw-result:hover {
  background-color: rgb(92, 92, 92);
  color: rgb(207, 207, 207);
}
.draw-result:active {
  background-color: rgb(97, 97, 97);
  color: rgb(160, 160, 160);
}

.white-result {
  background-color: white;
  color: black;
  /* border: none; */
  /* color: var(--accent); */
}
.white-result:hover {
  background-color: rgb(201, 201, 201);
  color: rgb(58, 58, 58);
}
.white-result:active {
  background-color: rgb(160, 160, 160);
  color: rgb(88, 88, 88);
}

.selected-result {
  border: calc(var(--unit) * .3) solid var(--accent);
  filter: none;
}
.selected-result:hover {
  filter: none;
}

/*
 ---------------------------- BOARD ----------------------------
*/
.tournament-graph-container-top {
  margin: 0 auto;
  margin-top: calc(var(--unit) * 1.6);
  margin-bottom: calc(var(--unit) * 1.6);
  /* width: min(calc(var(--unit) * (87.8 - 1.6 * 4)), 95%); */
  height: calc(var(--unit) * (87.8 - 1.6 * 4) / 3);
  aspect-ratio: 3.5;
  align-items: center;
  /* height: max(calc(var(--unit) * (87.8 - 1.6 * 4) / 3), 100%); */
  /* height: 100%; */
}
.tournament-graph-container {
  width: min(calc(var(--unit) * (87.8 - 1.6 * 4)), 60%);
  aspect-ratio: 3;
}

.line-graph {
  /* transition-duration: .5s; */
  /* width: 90%;
  height: 100%; */
  width: calc(var(--unit) * (87.8 - 1.6 * 4));
  width: 75vw;
  aspect-ratio: 2.5;
  /* width: 98%; */
  /* border: 5px solid red; */
  background-color: var(--dark-background);
  color: var(--text);
  margin: 0 auto;
  /* margin: calc(var(--unit) * .8); */
  /* margin-top: calc(var(--unit) * 1.6); */
  /* margin: calc(var(--unit) * 1.6); */
  border: calc(var(--unit) * .3) solid var(--accent);
  /* margin-bottom: calc(var(--unit) * 2.4); */
  /* font-size: calc(var(--unit) * 3.3); */
}
.line-graph:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
@media screen and (max-width: 768px) {
  .line-graph {
    width: 95vw;
    aspect-ratio: 1.5;
  }
}

.game-graph {
  /* transition-duration: .5s; */
  background-color: var(--light-background);
  margin: 0 auto;
  margin-left: calc(var(--unit) * 1);
  margin-right: calc(var(--unit) * 1);
  /* border: calc(var(--unit) * .5) solid var(--accent); */
  width: 70%;
  height: 100%;
  border-top: 0;
}
.game-graph:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.board-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: calc(var(--unit) * .2);
}

.no-transition {
  transition-duration: 0;
}

.board-stuff-and-graph {
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  /* transition-duration: .5s; */
  /* width: 100%; */
  /* border: 2px solid blue; */
  background-color: var(--dark-background);
  text-align: center;
  align-items: center;
  margin: calc(var(--unit) * .8);
  border: calc(var(--unit) * .3) solid var(--accent);
  margin-top: calc(var(--unit) * 2);
}
.board-stuff-and-graph h2 {
  color: var(--text);
  /* padding-top: calc(var(--unit) * .5); */
  font-size: calc(var(--unit) * 2);
}
.board-stuff-and-graph h3 {
  color: var(--text);
  /* padding-top: calc(var(--unit) * 1.2); */
  font-size: calc(var(--unit) * 1.8);
}
@media screen and (max-width: 768px) {
  .board-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .board-in-grid h3 {
    font-size: calc(var(--unit) * 3.2);
  }
}

.board-and-stuff {
  /* filter: hue-rotate(30deg); */
  background-color: var(--light-background);
  margin: calc(var(--unit) * .8);
  /* position: relative; */
  align-items: center;
  transition-duration: .5s;
  border: calc(var(--unit) * .5) solid var(--accent);
  /* border: 1px solid red; */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}
.board-and-stuff:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.board-and-stuff2 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  /* filter: hue-rotate(30deg); */
  background-color: var(--light-background);
  margin: calc(var(--unit) * .8);
  /* position: relative; */
  align-items: center;
  transition-duration: .5s;
  border: calc(var(--unit) * .5) solid var(--accent);
    width: 35vw;
    aspect-ratio: 4;
  /* border: 1px solid red; */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}
.board-and-stuff2:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

@media screen and (max-width: 768px) {
  .board-and-stuff2 {
    width: 90vw;
    aspect-ratio: 4;
  }
}

.board {
  z-index: 1;
  filter: saturate(50%);
  pointer-events: auto;
  position: relative;
  /* left: 256px;
  top: 50%; */
  /* transform: translate(-50%, -50%); */
  align-items: center;
  /* border: 1px solid blue; */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
  box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.35), 0 0px 50px 0 rgba(0, 0, 0, 0.44);
}
.board:hover {
  cursor: pointer;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.376), 0 17px 50px 0 rgba(0, 0, 0, 0.283); */
}
/* .board .square {
  filter: saturate(150%) hue-rotate(30deg);
} */
.board .piece {
  filter: saturate(200%) contrast(150%) hue-rotate(var(--secondary-rotate));
}

/*
 ---------------------------- PIECES ----------------------------
*/
.piece {
  /* filter: saturate(67%); */
  pointer-events: auto;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 12.5%;
  height: 12.5%;
  /* border: 1px solid green; */
  transition-duration: 0s;
}
/* .piece * {
} */
.piece:hover {
  filter: hue-rotate(var(--secondary-rotate));
  cursor: pointer;
  /* transition-duration: .5s; */
  box-shadow: 0 12px 12px 2px var(--glow-color);
  background-color: var(--piece-background);
}
.piece:active {
  transition-duration: 0s;
  background-color: rgba(255, 229, 80, 0);
}

/*
 ---------------------------- SQUARES ----------------------------
*/
.square {
  /* filter: hue-rotate(var(--secondary-rotate)); */
  /* pointer-events: none; */
  /* filter: saturate(400%); */
  display: inline-block;
  justify-content: space-evenly;
  align-items: center;
  /* width: 64px;
  height: 64px; */
  position: absolute;
  left: 0px;
  top: 0px;
}
.square:hover {
  /* filter: hue-rotate(var(--secondary-rotate)); */
  /* pointer-events: none; */
  background-color: var(--square-background);
  /* border: 3px solid var(--accent); */
  box-shadow: 0 12px 12px 0 var(--glow-color);
}
.square:active {
  pointer-events: none;
}

.start {
  /* filter: hue-rotate(30deg); */
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: var(--square-background);
  /* width: 64px;
  height: 64px; */
  display: inline-block;
  filter: saturate(150%) hue-rotate(var(--secondary-rotate));
}

.move {
  /* filter: hue-rotate(30deg); */
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: var(--square-background);
  /* width: 24px;
  height: 24px; */
  border-radius: 50%;
  display: inline-block;
  /* transform: translate(20px, 20px); */
  filter: saturate(300%) hue-rotate(var(--secondary-rotate));
}

.capture {
  /* filter: hue-rotate(30deg); */
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: var(--square-background);
  /* width: 64px;
  height: 64px; */
  border-radius: 50%;
  display: inline-block;
  filter: saturate(300%) contrast(200%) hue-rotate(var(--secondary-rotate));
}


.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px solid black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  pointer-events: none;
  width: calc(var(--unit) * 12.0);
  background-color: var(--dark-background);
  color: var(--text);
  text-align: center;
  padding: calc(var(--unit) * .5) 0;
  border-radius: calc(var(--unit) * .6);
  font-size: calc(var(--unit) * 1.8);

  border: calc(var(--unit) * .2) solid var(--accent);

  position: absolute;
  transform: translate(-50%, -150%);
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.clickable-th .tooltiptext {
  visibility: hidden;
  pointer-events: none;
  width: calc(var(--unit) * 12.0);
  background-color: var(--dark-background);
  color: var(--text);
  text-align: center;
  padding: calc(var(--unit) * .5) 0;
  border-radius: calc(var(--unit) * .6);
  font-size: calc(var(--unit) * 1.8);

  border: calc(var(--unit) * .2) solid var(--accent);

  position: absolute;
  z-index: 1;
}
.clickable-th:hover .tooltiptext {
  visibility: visible;
  pointer-events: none;
  width: calc(var(--unit) * 10.0);
  background-color: var(--dark-background);
  color: var(--text);
  text-align: center;
  padding: calc(var(--unit) * .5) 0;
  border-radius: calc(var(--unit) * .6);
  font-size: calc(var(--unit) * 1.6);

  border: calc(var(--unit) * .2) solid var(--accent);

  position: absolute;
  transform: translate(-75%, -120%);
  z-index: 1;
}


.help {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(var(--unit) * 3.0);
  height: calc(var(--unit) * 3.0);
  font-size: calc(var(--unit) * 2.4);
  transform: translate(0,0);
  display: inline-block;
  z-index: 5;
}
/* .help-follow {
  position: relative;
  right: 0;
  display: flex;
} */

.help .help-text {
  visibility: hidden;
  pointer-events: none;
  width: max-content;
  background-color: var(--dark-background);
  color: var(--text);
  text-align: left;
  padding: calc(var(--unit) * 2.0);
  border-radius: calc(var(--unit) * .6);
  font-size: calc(var(--unit) * 1.8);

  border: 2px solid var(--accent);

  position: absolute;
  transform:translate(-100%, calc(var(--unit) * .2));
  z-index: 5;

  white-space: pre-wrap;
}

.help:hover .help-text {
  visibility: visible;
}


.result-overlay {
  position: absolute;
  z-index: 40;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.505);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.result {
  z-index: 4;
  color: white;
  font-size: calc(var(--unit) * 3.2);
}


.fail-popup {
  width: calc(var(--unit) * 60.0);
  color: rgb(255, 255, 255);
  background-color: maroon;
  margin: 0 auto;
}

.fail-text {
  text-align: center;
  font-size: calc(var(--unit) * 2.4);
  padding: calc(var(--unit) * 1.0);
}

.info-text {
  text-align: center;
  color: var(--accent);
  font-size: calc(var(--unit) * 3.0);
  /* padding-top: 50px; */
}
.info-text2 {
  text-align: center;
  color: var(--text-hover);
  font-size: calc(var(--unit) * 2.4);
  padding-top: calc(var(--unit) * 1.0);
}
.info-text3 {
  text-align: center;
  color: var(--text);
  font-size: calc(var(--unit) * 1.8);
  padding-top: calc(var(--unit) * .5);
}

.half-second-transition {
  transition-duration: .5s;
}


.about {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: calc(var(--unit) * 3.2);
  color: var(--text-hover);
  padding-bottom: calc(var(--unit) * 3.2);
}
.about h2 {
  color: var(--accent);
  font-size: calc(var(--unit) * 3.2);
  text-align: center;
}
.about h4 {
  font-size: calc(var(--unit) * 1.8);
  width: 100%;
  text-align: center;
}
.about h3 {
  font-size: calc(var(--unit) * 2.4);
  text-align: left;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: calc(var(--unit) * 1.6);
  margin-bottom: calc(var(--unit) * .8);
}
.about h5 {
  font-size: calc(var(--unit) * 1.8);
  text-align: left;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: calc(var(--unit) * .8);
  margin-bottom: calc(var(--unit) * .8);
}
.about p {
  color: var(--text);
  text-align: left;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: calc(var(--unit) * .8);
  font-size: calc(var(--unit) * 1.6);
}
.general-link {
  font-size: calc(var(--unit) * 1.6);
  margin: 0;
  padding: 0;
}

.moves-with-buttons {
  margin-top: calc(var(--unit) * 1.6);
}
.toggle-button {
  /* margin-top: calc(var(--unit) * 1.6); */
  margin-bottom: 0;
  background-color: var(--dark-background);
}
.bottom-button {
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}
.top-button {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}
.toggle-holder {
  display: flex;
  justify-content:center;
  /* align-items: center;
  width: fit-content; */
}
.toggle-holder h3 {
  /* font-size: calc(var(--unit) * 1);
  color: blue; */
}

.standings-and-moves h3 {
  font-size: calc(var(--unit) * 1.8);
}
.in-line-button h4 {
  font-size: calc(var(--unit) * 1);
}

.toggle-button h3 {
  padding: calc(var(--unit) * .5);
  font-size: calc(var(--unit) * 1.2);
}
.toggle-on2 {
  border: calc(var(--unit) * .3) solid var(--accent);
  color: var(--text-hover);
  border-bottom: none;
}
.toggle-off2 {
  border: none;
  color: var(--text);
  border-bottom: none;
}
.toggle-on3 {
  border: calc(var(--unit) * .3) solid var(--accent);
  color: var(--text-hover);
  border-top: none;
}
.toggle-off3 {
  border: none;
  color: var(--text);
  border-top: none;
}
@media screen and (max-width: 768px) {
  .standings-and-moves {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .standings-and-moves h3 {
    font-size: calc(var(--unit) * 3.4);
  }
  .standings-and-moves th {
    font-size: calc(var(--unit) * 4.0);
    padding: calc(var(--unit) * 1.0);
  }
  .standings-and-moves td {
    font-size: calc(var(--unit) * 2.4);
    padding: calc(var(--unit) * 1.0);
  }
  .standings-container td {
    font-size: calc(var(--unit) * 4.5);
    padding: calc(var(--unit) * 1.0);
  }
  .smaller {
    height: calc(var(--unit) * 5.5);
    width: calc(var(--unit) * 5.5);
  }
  .in-line-button h2 {
    font-size: calc(var(--unit) * 4.5);
    padding: calc(var(--unit) * .5);
  }
  .in-line-button h4 {
    font-size: calc(var(--unit) * 2);
  }
  .result-overlay h2 {
    font-size: calc(var(--unit) * 5);
  }
  .toggle-holder h3 {
    font-size: calc(var(--unit) * 3.4);
  }
  .board-stuff-and-graph h2 {
    font-size: calc(var(--unit) * 4.5);
  }
}
